import React, { FC } from "react"
import { useNavigate } from "@reach/router"
import { graphql } from "gatsby"
import marked from "marked"
import Layout from "../components/Layout"

interface NotFoundPageTemplateProps {
  html: string
}

const NotFoundPageTemplate: FC<NotFoundPageTemplateProps> = ({
  html
}) => {
  const navigate = useNavigate()
  return (
    <main
      id="not-found-page"
      className="page px-6 py-32"
    >
      <div className="page-section-text-wrapper max-w-xl my-32">
        <button
          onClick={() => {
            navigate(-1)
          }}
        >
          &larr; Gå tilbake
        </button>

        <div
          className="mt-12 markdown"
          dangerouslySetInnerHTML={{
            __html: marked(html)
          }}
        />
      </div>
    </main>
  )
}

interface NotFoundPageProps {
  data: {
    markdownRemark: {
      html: string
    }
  }
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ data }) => {
  const { html } = data.markdownRemark
  return (
    <Layout>
      <NotFoundPageTemplate
        html={html}
      />
    </Layout>
  )
}

export default NotFoundPage

export const NotFoundPageQuery = graphql`
  query NotFoundPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`
